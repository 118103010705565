<template>
  <div class="about">
    <NavBar />
    <div class="banner">
      <p class="banner-title">公司介绍</p>
    </div>
    <div class="company">
      <div class="bottom-circle">
        <img src="@/assets/img/about/circle.png" />
      </div>
      <div class="company-desc">
        <div class="commom-title">公司介绍</div>
        <div class="company-content">
          <div class="text">
            江苏众享金联科技有限公司是国内外领先的区块链技术研发与应用落地的国家高新技术企业，目前已积累百余项软著和专利。公司以完备的区块链产品工程化能力以及贴近客户的服务能力在数字政务、智慧城市、金融科技、绿色双碳、乡村振兴、产品溯源等关键领域均实现产品落地及项目建设，平稳支撑近万亿的业务规模。众享金联与南京大学成立区块链联合实验室，与东南大学成立区块链联合创新中心，积极践行“产学研用”的发展模式。
          </div>
          <img src="https://image.peerfintech.cn/peerfintech_website_v2/about/company.png" />
        </div>
      </div>
    </div>
    <div class="advantage">
      <div class="advantage-title">我们的优势</div>
      <div class="advantage-content">
        <div class="advantage-item">
          <div class="title">
            <img src="@/assets/img/about/advantage-1.png" />
            <span>更稳定</span>
          </div>
          <p>
            <span class="text"
              >成功交付全球最大规模联盟链商业项目，业务资金突破万亿</span
            >
          </p>
          <p>
            <span class="text">在金融高要求生产环境成功稳定运行近3年</span>
          </p>
        </div>
        <div class="advantage-item">
          <div class="title">
            <img src="@/assets/img/about/advantage-2.png" />
            <span>更高效</span>
          </div>
          <p>
            <span class="text">和企业传统数据库完美对接，降低接入门槛</span>
          </p>
          <p>
            <span class="text">一键部署区块链，可视化运维</span>
          </p>
          <p>
            <span class="text">TPS最高性能可达10万，业内领先</span>
          </p>
        </div>
        <div class="advantage-item">
          <div class="title">
            <img src="@/assets/img/about/advantage-3.png" />
            <span>更成熟</span>
          </div>
          <p>
            <span class="text"
              >成功交付多个行业首个标杆性商用案例，包括建设银行、中信银行、江苏银行、苏宁、上海期货交易所、江苏电力、河南电力、海关总署等</span
            >
          </p>
        </div>
        <div class="advantage-item">
          <div class="title">
            <img src="@/assets/img/about/advantage-4.png" />
            <span>更安全</span>
          </div>
          <p>
            <span class="text">唯一通过国家密码局认定的区块链平台</span>
          </p>
          <p>
            <span class="text"
              >国内第一家通过央行《JR/T 0184—2020》金融标准的技术平台</span
            >
          </p>
        </div>
      </div>
    </div>
    <div class="development">
      <div class="development-title">发展历程</div>
      <div class="development-content">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in swiperLsit"
              :key="index"
              :data-index="index"
            >
              <div
                class="swiper-item"
                :class="{ 'active-swiper-item': index === activeSwiperIndex }"
              >
                {{ item.year }}
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-img">
          <img :src="swiperLsit[activeSwiperIndex].url" />
        </div>
        <div class="arrow-left" @click="swiperPrev">
          <img src="@/assets/img/about/triangle-left.png" />
        </div>
        <div class="arrow-right" @click="swiperNext">
          <img src="@/assets/img/about/triangle-right.png" />
        </div>
      </div>
      <div class="line"></div>
    </div>
    <div class="certificate">
      <div class="commom-title">资质证书</div>
      <div class="certificate-content content-width">
        <div class="certificate-top">
          <div class="top-item">
            <img src="@/assets/img/about/certificate-1.png" />
            <div class="desc">
              <p class="desc-title">高新技术企业</p>
              <p class="desc-text">
                高新技术企业是指在《国家重点支持的高新技术领域》内，持续进行研究开发与技术成果转化，形成企业核心自主知识产权，并以此为基础开展经营活动，在中国境内（不包括港、澳、台地区）注册一年以上的居民企业，是知识、技术密集的经济实体。
              </p>
            </div>
          </div>
          <div class="top-item">
            <img src="@/assets/img/about/certificate-2.png" />
            <div class="desc">
              <p class="desc-title">CMMI 3资质证书</p>
              <p class="desc-text">
                CMMI
                3，企业不仅能够对项目的实施有一整套的管理措施，并保障项目的完成；而且也能够根据自身的特殊情况以及自己的标准流程，将这套管理体系与流程予以制度化，不仅能够在同类的项目上得到成功的实施，在不同类的项目上也能得到成功的实施。
              </p>
            </div>
          </div>
        </div>
        <div class="certificate-img">
          <img src="https://image.peerfintech.cn/peerfintech_website_v2/about/certificate-img.png" />
        </div>
        <div class="certificate-more">
          <div class="center-line"></div>
          <div class="center-text">获得众多资质证书</div>
          <div class="center-line"></div>
        </div>
        <div class="certificate-bottom">
          <img src="@/assets/img/about/certificate-3.png" />
          <img src="@/assets/img/about/certificate-4.png" />
          <img src="@/assets/img/about/certificate-5.png" />
          <img src="@/assets/img/about/certificate-6.png" />
          <img src="@/assets/img/about/certificate-7.png" />
          <img src="@/assets/img/about/certificate-8.png" />
        </div>
      </div>
    </div>
    <div class="contact">
      <div class="commom-title">联系我们</div>
      <div class="contact-desc content-width">
        <div class="left">
          <img src="@/assets/img/about/link-logo.png" class="logo" />
          <div class="text">江苏众享金联科技有限公司</div>
          <p>
            网址：<a
              href="http://www.peerfintech.com"
              target="_blank"
              style="color: rgba(32, 118, 222, 1)"
              >http://www.peerfintech.com</a
            >
          </p>
          <p>地址：江苏省南京市鼓楼区清江南路18号鼓楼创新广场B座10楼</p>
          <p>电话：025-58860124</p>
          <p>邮箱：info@peerfintech.cn</p>
        </div>
        <img class="right" src="https://image.peerfintech.cn/peerfintech_website_v2/about/map.png" />
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import Swiper from "swiper";
import ScrollReveal from "scrollreveal";
export default {
  components: {
    NavBar,
    Footer,
  },

  data() {
    return {
      mySwiper: null,
      swiperLsit: [
        {
          year: 2022,
          url: 'https://image.peerfintech.cn/peerfintech_website_v2/about/development-2022.png',
        },
        {
          year: 2021,
          url: 'https://image.peerfintech.cn/peerfintech_website_v2/about/development-2021.png',
        },
        {
          year: 2020,
          url: 'https://image.peerfintech.cn/peerfintech_website_v2/about/development-2020.png',
        },
        {
          year: 2019,
          url: 'https://image.peerfintech.cn/peerfintech_website_v2/about/development-2019.png',
        },
        {
          year: 2018,
          url:'https://image.peerfintech.cn/peerfintech_website_v2/about/development-2018.png',
        },
        {
          year: 2017,
          url: 'https://image.peerfintech.cn/peerfintech_website_v2/about/development-2017.png',
        },
        {
          year: 2016,
          url: 'https://image.peerfintech.cn/peerfintech_website_v2/about/development-2016.png',
        },
      ],
      activeSwiperIndex: 0,
    };
  },
  methods: {
    swiperPrev() {
      this.mySwiper.swipePrev();
    },
    swiperNext() {
      this.mySwiper.swipeNext();
    },
  },
  mounted() {
    ScrollReveal().reveal(document.querySelector(".company-desc"), { duration: 1000, distance: "100px", origin: "bottom",reset:true });
    ScrollReveal().reveal(document.querySelector(".swiper-img"), { duration: 1000, distance: "100px", origin: "top", reset:true});
    ScrollReveal().reveal(document.querySelector(".certificate-content"), { duration: 1000, distance: "100px", origin: "bottom", reset:true});
    ScrollReveal().reveal(document.querySelector(".certificate-bottom"), { duration: 1000, distance: "100px", origin: "bottom", reset:true});

    let that = this;
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.mySwiper = new Swiper(".swiper-container", {
      loop: true, // 循环模式选项
      slidesPerView: 4, //设置slider容器能够同时显示的slides数量(carousel模式)。
      moveStartThreshold: 5,
      onSlideClick: function (swiper) {
        that.activeSwiperIndex = Number(
          swiper.clickedSlide.getAttribute("data-index")
        );
        for (
          let i = 0;
          i < document.querySelectorAll(".swiper-slide").length;
          i++
        ) {
          if (
            document
              .querySelectorAll(".swiper-slide")
              [i].getAttribute("data-index") ===
            swiper.clickedSlide.getAttribute("data-index")
          ) {
            document.querySelectorAll(".swiper-slide")[
              i
            ].children[0].className = "active-swiper-item";
          } else {
            document.querySelectorAll(".swiper-slide")[
              i
            ].children[0].className = "swiper-item";
          }
        }
      },
    });
  },
};
</script>
<style scoped lang="scss">
.about {
  .content-width {
    width: 62.5%;
    min-width: 1200px;
    margin: 0px auto;
  }

  .commom-title {
    font-family: "PingFangSC-Medium";
    font-size: 32px;
    color: #000000;
    line-height: 45px;
    padding: 70px 0px 50px;
    text-align: center;
  }
  .banner {
    margin-top: 64px;
    width: 100%;
    height: 476px;
    background: url("https://image.peerfintech.cn/peerfintech_website_v2/about/bg.png") center/cover no-repeat;
    .banner-title {
      @extend .content-width;
      font-size: 36px;
      font-family: "PingFangSC-Medium";
      color: #ffffff;
      line-height: 476px;
    }
  }
  .company {
    height: 505px;
    background: url("https://image.peerfintech.cn/peerfintech_website_v2/about/company-bg.png") center/cover
      no-repeat;
    position: relative;
    .bottom-circle {
      width: 100%;
      height: 64px;
      position: absolute;
      bottom: 0px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .company-desc {
      @extend .content-width;
      height: 505px;
      background-color: #ffffff;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 50px;
      .company-content {
        display: flex;
        padding: 0px 70px;
        justify-content: space-between;
        .text {
          width: 577px;
          font-size: 18px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 36px;
        }
        img {
          width: 383px;
          height: 270px;
        }
      }
    }
  }
  .advantage {
    height: 419px;
    background: #0075ff;
    .advantage-title {
      @extend .commom-title;
      color: #ffffff;
      padding-top: 120px;
    }
    .advantage-content {
      @extend .content-width;
      display: flex;
      justify-content: space-between;
      .advantage-item {
        width: 234px;
        .title {
          display: flex;
          align-items: center;
          font-size: 24px;
          font-family: "PingFangSC-Medium";
          color: #ffffff;
          height: 34px;
          margin-bottom: 16px;
          img {
            width: 24px;
            height: 24px;
            margin-right: 16px;
          }
        }
        .text {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.85);
          line-height: 20px;
          &::before {
            content: "";
            display: inline-block;
            width: 3px;
            height: 3px;
            background-color: rgba(255, 255, 255, 0.85);
            vertical-align: middle;
            border-radius: 50%;
            margin-right: 3px;
          }
        }
        &:hover{
          transform: scale(1.2);
          transition: all 0.3s;
        }
      }
    }
  }
  .development {
    background: url("https://image.peerfintech.cn/peerfintech_website_v2/about/development-bg.png") center/cover
      no-repeat;
    position: relative;
    .development-title {
      @extend .commom-title;
      color: #ffffff;
    }
    .development-content {
      @extend .content-width;
      position: relative;
      .swiper-container {
        height: 110px;
        width: 100%;
        .swiper-slide {
          width: 25%;
          height: 110px;
          cursor: pointer;
          .swiper-item {
            margin-top: 18px;
            height: 92px;
            line-height: 92px;
            background-color: #ffffff;
            font-size: 37px;
            color: #000000;
            text-align: center;
            transition: all 0.1s;
            &:hover{
            margin-top: 0px;
            height: 110px;
            line-height: 110px;
            background-color: #0075ff;
            font-size: 37px;
            color: #ffffff;
            text-align: center;

            }
          }
          .active-swiper-item {
            margin-top: 0px;
            height: 110px;
            line-height: 110px;
            background-color: #0075ff;
            font-size: 37px;
            color: #ffffff;
            text-align: center;
          }
        }
      }
      .swiper-img {
        padding: 70px 0px;
        width: 100%;
        height: 746px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .arrow-left,
      .arrow-right {
        width: 22px;
        height: 50px;
        position: absolute;
        top: 39px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
        &:hover{
          transform: scale(1.1);
        }
      }
      .arrow-left {
        left: -82px;
      }
      .arrow-right {
        right: -82px;
      }
    }
    .line {
      width: 100%;
      height: 1px;
      background-color: #828282;
      position: absolute;
      bottom: 373px;
    }
  }
  .certificate {
    // height: 662px;
    background: url("https://image.peerfintech.cn/peerfintech_website_v2/about/certificate-bg.png") center/cover
      no-repeat;
      padding-bottom:70px;
    .certificate-content {
      .certificate-top {
        display: flex;
        justify-content: space-between;
        .top-item {
          width: 588px;
          background: linear-gradient(360deg, #ffffff 0%, #f0f3f6 100%);
          box-shadow: 0px 20px 24px 0px rgba(0, 58, 126, 0.04);
          border: 3px solid #ffffff;
          padding: 21px;
          display: flex;
          justify-content: space-between;
          img {
            width: 170px;
            height: 120px;
            margin-right: 24px;
          }
          .desc {
            .desc-title {
              font-size: 18px;
              color: #000000;
              line-height: 25px;
            }
            .desc-text {
              font-size: 12px;
              color: rgba(0, 0, 0, 0.85);
              line-height: 18px;
              margin-top: 10px;
            }
          }
          &:hover{
            transform: scale(1.05);
            transition: all 0.3s;
          }
        }
      }
      .certificate-img {
        margin-top: 20px;
        background: linear-gradient(360deg, #ffffff 0%, #f0f3f6 100%);
        box-shadow: 0px 20px 24px 0px rgba(0, 58, 126, 0.04);
        border: 3px solid #ffffff;
        padding:15px;
        img {
          width: 100%;
        }
        &:hover{
            transform: scale(1.05);
            transition: all 0.3s;
          }
      }
      .certificate-more {
      margin: 36px 0px;
      display: flex;
      align-items: center;
      .center-line {
        flex: 1;
        height: 1px;
        background-color: #d7dadd;
      }
      .center-text {
        font-size: 16px;
        color: #7d8996;
        line-height: 22px;
        padding: 0px 32px;
      }
    }
    .certificate-bottom {
      height: 151px;
      background: linear-gradient(360deg, #ffffff 0%, #f0f3f6 100%);
      box-shadow: 0px 20px 24px 0px rgba(0, 58, 126, 0.04);
      border: 3px solid #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-around;
      img {
        width: 120px;
        height: 112px;
        &:hover{
            transform: scale(1.15);
            transition: all 0.3s;
          }
      }
    }
    }
  
  }
  .contact {
    .contact-desc {
      display: flex;
      justify-content: space-between;
      padding-bottom:70px;
      .left {
        .logo {
          width: 286px;
          height: 60px;
        }
        .text {
          margin: 50px 0px;
          font-size: 28px;
          color: #000000;
          line-height: 40px;
        }
        p {
          font-size: 24px;
          color: rgba(0, 0, 0, 0.65);
          line-height: 33px;
          margin-bottom: 24px;
          &:last-of-type{
            margin-bottom: 0;
          }
        }
      }
      .right {
        width: 453px;
        height: 404px;
        img {
          width: 100%;
          height: 100%;
        }
        &:hover{
            transform: scale(1.15);
            transition: all 0.3s;
          }
      }
    }
  }
}
</style>
